/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

.loader {
  border-top-color: #3498db;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}